@import 'icons/icomoon-codes';

@include font-face('icomoon-ultimate', '#{$font-path}/icomoon/icomoon-ultimate', $file-formats: woff ttf eot);

.icomoon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon-ultimate' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    display: inline-block;
    font-size: inherit;
    text-rendering: auto;
    // -webkit-font-smoothing: antialiased;
    // -moz-osx-font-smoothing: grayscale;
    // transform: translate(0, 0);
    vertical-align: bottom;
    line-height: inherit;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;

    /* Better Font Rendering =========== */
    //-webkit-font-smoothing: antialiased;
    //-moz-osx-font-smoothing: grayscale;

    @each $name, $code in $icomoon-codes {
        &.#{$name}::before {
            content: '#{$code}';
        }
    }
}
