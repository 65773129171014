@import '../node_modules/bourbon/app/assets/stylesheets/bourbon';


$font-path: '/assets/fonts' !default;

//@include font-face('Lato', '#{$font-path}/core-sans-a/CoreSansA-Light', 300);
@include font-face('Lato', '#{$font-path}/lato/Lato-Regular');
//@include font-face('Lato', '#{$font-path}/core-sans-a/CoreSansA-Medium', 500);
@include font-face('Lato', '#{$font-path}/lato/Lato-Medium', 500);
@include font-face('Lato', '#{$font-path}/lato/Lato-Bold', 600);

@import 'elements/font-icons';

.with-box-shadow {
    box-shadow: 0 15px 70px -5px rgba(36, 34, 47, 0.13);
}

.b-r-0 {
    border-radius: 0 !important;
}

.no-shadow {
    box-shadow: none !important;
}
